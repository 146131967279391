import React, { FC, memo, useState } from 'react'
import styled, { StyledProps } from 'styled-components'
import Link from 'next/link'
import { Box } from '@ucheba/ui/components/Box/touch'
import { Heading } from '@ucheba/ui/components/Heading/touch'
import { Description } from '@ucheba/ui/components/Description/touch'
import { Button } from '@ucheba/ui/components/Button/touch'
import { Spacing } from '@ucheba/ui/components/Spacing/touch'
import { useBottomSheetHeight } from '@ucheba/ui/components/Select/bll'
import { BottomSheet } from '@ucheba/ui/components/BottomSheet/touch'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/touch'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Tooltip } from '@ucheba/ui/components/Tooltip/desktop'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { useBannerBottomSheet } from '../bll'
import { EButtonColorByTheme, IBannerVariantProps } from '../types'
import SC from '../base/Banner.styles'

const { DotContainer, Dot, DotPulse } = SC

const Image = styled.img`
  width: 100%;
`

const TextImage = styled.img`
  max-width: 204px;
  height: auto;
`

const TextImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 40px;

  & img {
    max-height: 40px;
    width: auto;
  }
`

const ResetBottomRadiusBox = styled.div`
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
`

const AdvText = styled.div`
  z-index: 999999;
  cursor: default;
  display: flex;
  justify-content: center;
  padding: 8px 0;
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
`

const TextAdv = styled(Text)`
  letter-spacing: 1px;
`

const TextST = styled(Text)`
  letter-spacing: 1.4px;
`

const VideoBannerWrap = styled.div<StyledProps<any>>`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  cursor: ${(props) => (props.url ? 'pointer' : 'default')};
  min-width: 322px;
  background-color: ${(props) => sv.color[props.color] || sv.color.transparent};
`

/** Компонент рекламный баннерр */
const BannerBottomSheet: FC<IBannerVariantProps> = (props) => {
  const { content, pixelImg, companyPixelUrl } = props
  const [isOpenBottomSheet, toggleOpenBottomSheet] = useState(false)

  const {
    text,
    text_image_url,
    url,
    url_text,
    image_url,
    heading,
    color_theme = 'gold',
    overTitle,
    clientName,
    url_video,
    is_live,
  } = content

  const bottomSheetBanner = useBottomSheetHeight({ isOpen: isOpenBottomSheet })

  useBannerBottomSheet({ content, toggleOpenBottomSheet })

  return (
    <BottomSheet
      open={isOpenBottomSheet}
      toggleOpen={toggleOpenBottomSheet}
      ref={bottomSheetBanner.bottomSheetRef}
      height={bottomSheetBanner.bottomSheetHeight}
      overflowForBody='visible'
      isScrollable={false}
    >
      <ResetBottomRadiusBox as={Box} color={color_theme} spacing='null'>
        {clientName && (
          <AdvText>
            <Tooltip
              content={<Text color='black'>{clientName}</Text>}
              position='bottom-center'
              width='300px'
            >
              <TextAdv
                textAlign='center'
                fontSize='xsmall1'
                lineHeight='xsmall1'
                color='white50'
              >
                РЕКЛАМА
              </TextAdv>
            </Tooltip>
          </AdvText>
        )}

        {url_video ? (
          <Link
            href={url}
            rel='noindex nofollow'
            style={{ color: 'inherit' }}
            target='_blank'
          >
            <VideoBannerWrap rel='noindex nofollow' url={url} color={color_theme}>
              {heading && (
                <Box color={color_theme} spacing='small'>
                  <Text as='h4' fontSize='h4' lineHeight='h4' fontWeight='extrabold'>
                    {heading}
                  </Text>
                </Box>
              )}

              <video
                width='100%'
                preload='auto'
                autoPlay
                loop
                playsInline
                muted
                style={{
                  cursor: 'pointer',
                  borderRadius: '8px',
                }}
              >
                <source src={url_video} type='video/webm' />

                <source src={url_video} type='video/mp4' />
              </video>

              {url_text && (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '0',
                    left: '0',
                    width: '100%',
                    padding: '16px',
                  }}
                >
                  <Button size='small' color='black' block>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {is_live && (
                        <div>
                          <DotContainer>
                            <Dot>
                              <DotPulse />
                            </Dot>
                          </DotContainer>
                        </div>
                      )}

                      <div>{url_text}</div>
                    </div>
                  </Button>
                </div>
              )}
            </VideoBannerWrap>
          </Link>
        ) : (
          <Box spacing='small'>
            {image_url && (
              <Image
                src={image_url}
                onError={(e) => {
                  const target = e.target as HTMLImageElement

                  target.style.display = 'none'
                }}
              />
            )}

            {!overTitle && <Spacing spacing='small' />}

            {overTitle && (
              <>
                <Spacing spacing='medium' />

                <TextST uppercase fontSize='small2'>
                  {overTitle}
                </TextST>

                <Spacing spacing='usmall' />
              </>
            )}

            <Heading h2={heading} spacing='xxsmall' />

            {text && <Description description={[text]} />}

            {url_text && (
              <>
                <Spacing spacing='small' />

                {text_image_url ? (
                  <Grid nowrap spacing='xxsmall'>
                    <GridCol shrink='initial' width='100%'>
                      <Button
                        rel='noindex nofollow'
                        block
                        href={url}
                        color={EButtonColorByTheme[color_theme]}
                      >
                        {url_text}
                      </Button>
                    </GridCol>

                    <GridCol width='auto'>
                      <TextImageWrapper>
                        <TextImage src={text_image_url} />
                      </TextImageWrapper>
                    </GridCol>
                  </Grid>
                ) : (
                  <Button
                    rel='noindex nofollow'
                    block
                    href={url}
                    color={EButtonColorByTheme[color_theme]}
                  >
                    {url_text}
                  </Button>
                )}
              </>
            )}
          </Box>
        )}
      </ResetBottomRadiusBox>

      {pixelImg && (
        <img
          className='adv-pixel-img'
          src={pixelImg}
          style={{
            width: '1px',
            height: '1px',
            opacity: 0,
            position: 'absolute',
            top: -9999,
            right: -9999,
          }}
          alt=''
        />
      )}

      {companyPixelUrl && (
        <img
          className='adv-companyPixel-img'
          src={companyPixelUrl}
          style={{
            width: '1px',
            height: '1px',
            opacity: 0,
            position: 'absolute',
            top: -9999,
            right: -9999,
          }}
          alt=''
        />
      )}
    </BottomSheet>
  )
}

export { BannerBottomSheet }
export default memo(BannerBottomSheet)
