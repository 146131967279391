import React, { FC, memo } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { EButtonColorByTheme, IBannerVariantProps } from '../types'

const Image = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 16px;
`

const Container = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 99;
`

const Content = styled.div<{ color: EButtonColorByTheme }>`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  background: ${({ color }) => sv.color[color]};
`

const TextContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 4px;
`

const LinkStyled = styled.a`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

/** Рекламный баннер прилипающий к низу  */
const BannerBottomSticky: FC<IBannerVariantProps> = ({
  content,
  pixelImg,
  companyPixelUrl,
}) => {
  const { text, icon_url, title, url, color_theme = 'blue' } = content

  return (
    <Container>
      <LinkStyled as={Link} href={url} target='_blank' rel='noindex nofollow'>
        <Content color={color_theme}>
          <TextContent>
            <Text fontWeight='bold' color='white'>
              {title}
            </Text>

            <Text color='white'>{text}</Text>

            {pixelImg && (
              <img
                className='adv-pixel-img'
                src={pixelImg}
                style={{
                  width: '1px',
                  height: '1px',
                  opacity: 0,
                  position: 'absolute',
                  top: -9999,
                  right: -9999,
                }}
                alt=''
              />
            )}

            {companyPixelUrl && (
              <img
                className='adv-companyPixel-img'
                src={companyPixelUrl}
                style={{
                  width: '1px',
                  height: '1px',
                  opacity: 0,
                  position: 'absolute',
                  top: -9999,
                  right: -9999,
                }}
                alt=''
              />
            )}
          </TextContent>

          <Image src={icon_url} />
        </Content>
      </LinkStyled>
    </Container>
  )
}

export { BannerBottomSticky }
export default memo(BannerBottomSticky)
