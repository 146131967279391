import React, { FC, memo } from 'react'
import { EBannerTemplate } from '@ucheba/store/adv/types'
import { IBannerProps } from '../types'
import { useBannerCore } from '../bll'
import { BannerVertical } from './BannerVertical'
import { BannerBottomSheet } from './BannerBottomSheet'
import { BannerBottomSticky } from './BannerBottomSticky'
import BannerBottom from './BannerBottom'
import { BannerLidFormVertical } from './BannerLidFormVertical'
import { BannerLidFormBottomSheet } from './BannerLidFormBottomSheet'

/** Компонент рекламный баннерр */
const Banner: FC<IBannerProps> = (props) => {
  const {
    spacing = null,
    id,
    onDataExist,
    extraParams,
    onClick,
    onClickGetStateBanner,
  } = props

  const { content, pixelImg, advBannerId, companyPixelUrl } = useBannerCore({
    id,
    onDataExist,
    extraParams,
  })

  if (!content) return null

  return (
    <>
      {content?.template === EBannerTemplate.vertical && (
        <BannerVertical
          spacing={spacing}
          content={content}
          pixelImg={pixelImg}
          companyPixelUrl={companyPixelUrl}
        />
      )}

      {content?.template === EBannerTemplate.bottomSheet && (
        <BannerBottomSheet
          content={content}
          pixelImg={pixelImg}
          companyPixelUrl={companyPixelUrl}
        />
      )}

      {content?.template === EBannerTemplate.bottomSticky && (
        <BannerBottomSticky
          content={content}
          pixelImg={pixelImg}
          companyPixelUrl={companyPixelUrl}
        />
      )}

      {content?.template === EBannerTemplate.bottom && (
        <BannerBottom
          content={content}
          pixelImg={pixelImg}
          onClick={onClick}
          onClickGetStateBanner={onClickGetStateBanner}
          companyPixelUrl={companyPixelUrl}
        />
      )}

      {content?.template === EBannerTemplate.verticalForm && content && (
        <BannerLidFormVertical
          content={content}
          pixelImg={pixelImg}
          id={advBannerId}
          bannerId={id}
          companyPixelUrl={companyPixelUrl}
        />
      )}

      {content?.template === EBannerTemplate.bottomSheetForm && content && (
        <BannerLidFormBottomSheet
          content={content}
          pixelImg={pixelImg}
          id={advBannerId}
          bannerId={id}
          companyPixelUrl={companyPixelUrl}
        />
      )}
    </>
  )
}

export { Banner }
export default memo(Banner)
